import React from "react";

import Ready from "../ready/ready";
import TopBanner from "../top_banner/product_top_banner";
import TariffsTable from "../tariffs/tariffs-table";
import Exam from "../exam/exam";
import Approach from "../human_approach/approach";
import SecondaryHeader from "../secondary-header";
import { secondaryMenu } from "../../constants";
import { useTranslation } from 'react-i18next';

const Score = () => {
  const {t} = useTranslation();
  const exams = [
    {
      title: "Comment ?",
      description: `${t('your_score_will_be_available')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/woman-searching.svg",
    },
    {
      title: `${t('in_how_long')}`,
      description: `${t('your_results_will_be_posted')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/person-sitting-mobile.svg",
    },
    {
      title: `${t('period_of_validity')}`,
      description: `${t('mereos_stores_and_certifies')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/woman-marking-sheet.svg",
    },
    {
      title: `${t('revocation')}`,
      description: `${t('any_fraud_or_attempted')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/masking-before-monitor-lg.svg",
    },
  ];

  const tariffsData = [
    {
      title: "Products",
      list: [
          {title: `${t('digital_skills')}`, Standard: "30 questions", Scale: "40 minutes", Enterprise: "200 points"},
          {title: `${t('subtest_2_calculation')}`, Standard: "15", Scale: "20", Enterprise: "100"},
          {title: `${t('subtest_4_inimum_conditions')}`, Standard: "15", Scale: "20", Enterprise: "100"},
          {title: `${t('verbal_skills')}`, Standard: "30 questions", Scale: "40 minutes", Enterprise: "200 points"},
          {title: `${t('subtest_1_comprehension')}`, Standard: "15", Scale: "20", Enterprise: "100"},
          {title: `${t('subtest_5_xpression')}`, Standard: "15", Scale: "20", Enterprise: "100"},
          {title: `${t('deductive_skills')}`, Standard: "20 questions", Scale: "25 minutes", Enterprise: "200 points"},
          {title: `${t('subtest_3_Reasoning')}`, Standard: "10", Scale: "12,5", Enterprise: "100"},
          {title: `${t('subtest_6_logic')}`, Standard: "10", Scale: "12,5", Enterprise: "100"},
          {title: "TOTAL", Standard: "80 questions", Scale: "105 minutes (1h45)", Enterprise: "600 points"},
      ]
    }
  ];

  return (
    <div className={`font-sans`}>
      <SecondaryHeader
        menu={secondaryMenu}
      />
      <TopBanner
        pageTitle="Score"
        title={t('understanding_your_tasem_test_score')}
        description={t('scores_are_established_on_range')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/person-behind-sheet-lg.svg"
        page={"platform"}
      />

      <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
        <TariffsTable
          tableName={t('understanding_your_score')}
          tableDescription={t('only_one_answer_is_correct')}
          data={tariffsData}
          headers={[
            `${t('test')}`,
            `${t('number_of_questions')}`,
            `${t('duration')}`,
            `${t('score_per_event')}`,
          ]}
        />
        <Exam
          page={"AI"}
          title={t('access_your_score')}
          Data={exams}
        />

        <div className="my-20">
          <Approach
            title={t('how_to_send_your_score_to_school')}
            titleStyle={{
              textAlign: 'justify'
            }}
            description1={t('when_registering_the_candidate')}
            imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/person-sending-message.svg"
            descriptionStyle={{
              textAlign: 'justify'
            }}
          />
        </div>
        <div className="mx-auto my-20">
          <Ready
            title={t('ready_to_test_mereos')}
            description={t('discover_our_ready_to_use_solutions')}
            leftButton={{
              text: `${t('register')}`,
              href: "https://registration.mereos.eu/candidature/?id=1"
            }}
            rightButton={{
              text: t('learn_more'),
              href: ""
            }}
            image="https://d2lxkizvrhu4im.cloudfront.net/images/Market_launch.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Score;
