import React from "react";

import classes from "./tariffs.module.scss";

const TariffsTable = ({tableCategory, tableName, tableDescription, headers, data}) => {
    return (
        <div className="space-y-6 md:space-y-10">
            {
                tableCategory && 
                <div className={`text-xs md:text-base font-semibold opacity-60 text-center`}>
                    {tableCategory}
                </div>
            }
            {
                tableName &&
                <div className={`text-3xl md:text-6xl text-center font-bold  w-10/12 lg:w-4/5 mx-auto`}>
                    {tableName}
                </div>
            }

            {
                tableDescription && 
                <div className={`my-5 font-normal text-sm lg:text-xl text-gray-500 w-5/6 md:w-full mx-auto text-center`}>
                    {tableDescription}
                </div>
            }
            
            <table className="w-full text-sm text-gray-500 border-yellow-400 dark:bg-gray-700 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr className="border-yellow-300 font-bold" style={{borderWidth: '0px !important'}}>
                        {
                            headers.map((header, index) => (
                                <th
                                    key={index}
                                    scope="col"
                                    className={`px-6 py-3 text-center border-yellow-300 ${index === 0 ? 'bg-yellow-500' : 'bg-yellow-300'}`}
                                >
                                    {header}
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row) => (
                            row.list?.map((columns, index, rowList) => (
                                <tr
                                    key={index}
                                    className={`bg-white dark:bg-yellow-100 dark:border-yellow-300 ${index === rowList.length - 1 || index % 3 === 0 ? 'font-bold' : 'font-medium'}`}
                                    style={{borderWidth: '0px !important'}}
                                >
                                    {
                                        Object.keys(columns).map((column, index) => (
                                            <th
                                                key={index}
                                                scope="row"
                                                // rowSpan={index === 3 ? '3' : index === 4 ? '9' : 1}
                                                className={`p-4 text-gray-700 dark:text-white bg-yellow-50 border-yellow-300 ${index !== 0 && 'text-center'}`}
                                                style={{borderWidth: '1px'}}
                                                dangerouslySetInnerHTML={{__html: columns[column]}}
                                            ></th>
                                        ))
                                    }
                                </tr>
                            ))
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

export default TariffsTable;
