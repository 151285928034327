import * as React from "react";

import Layout from "../../../components/layout/layout";
import Seo from "../../../components/seo";
import Score from "../../../components/tasem-test/score";

const Index = () => {
  return (
    <>
      <Seo
        title="Comprendre son score au TASEM"
        description="Les scores sont établis sur un intervalle de 0 à 200 pour chaque sous-test. Le score total correspond à
        l’addition des scores des trois sous-tests. À noter que l’absence de réponse ne retire pas de points, 
        mais qu’une mauvaise réponse est sanctionnée."
      />
      <Layout>
        <Score />  
      </Layout>
    </>
  )
}

export default Index;
